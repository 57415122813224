
import { timeTransformer, htmlDecode, template } from '@shein/common-function'
import dayjs from 'dayjs'
import schttp from 'public/src/services/schttp'
import { MESSAGE_DATE, COUPON_TIME_FORMAT_NO_HOURS_BY_ABT }  from 'public/src/pages/common/biz_helper/timer_format/dictionary'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'

// 是否展示倒计时
export const isShowCountdownFlag = (couponInfo) => {
  const { selfStatus, timeStatus, end_date } = couponInfo
  // 即将开始
  if (selfStatus === '0' && timeStatus == '1') return false
  let [
    timeStep,
    newDate,
    endDate
  ] = [
    72 * 60 * 60 * 1000, //倒计时72小时
    new Date().getTime(), // 当前时间
    end_date * 1000 // 有效期结束时间
  ]
  // 不在倒计时范围内
  if (newDate + timeStep <= endDate) return false
  return true
}

// 列表券文案处理
// 模版 折扣文案格式 start
export const handelDiscountFormat = (couponInfo, ruleItem, language) => {
  const text = language.SHEIN_KEY_PC_15957 ?? '{0} OFF'
  if (couponInfo.coupon_type_id === '1') {
    return [
      `- ${ruleItem.value.amountWithSymbol}`,
      ''
    ]
  } else {
    return [
      percentFormat(ruleItem.value.amount),
      `%${text.replace('{0}', '')}`
    ]
  }
}

export const handelCouponSubTitle = (couponInfo, ruleItem, language) => {
  const min = ruleItem.min
  const max = ruleItem.max
  
  if ( max && max.amountWithSymbol ) return template(
    min.amountWithSymbol,
    max.amountWithSymbol,
    htmlDecode({ text: language.SHEIN_KEY_PC_19368 })
  )

  if (couponInfo.coupon_rule.length > 1) {
    return language.SHEIN_KEY_PC_26287?.replace('{0}', min.amountWithSymbol)
  } else {
    return language.SHEIN_KEY_PC_15958?.replace('{0}', min.amountWithSymbol)
  }
}

export const handlerDate = (date, couponTimeChangeAbt) => {
  if (!date) return date

  date = (typeof date == 'string') ? date.replaceAll('-', '/') : date
  date = Number(date) ? Number(date) * 1000 : new Date(date + ' GMT+0800').getTime()
  const timer = dayjs(date)

  let sDateMap = MESSAGE_DATE
  if(couponTimeChangeAbt) {
    sDateMap = COUPON_TIME_FORMAT_NO_HOURS_BY_ABT
  }

  const defaultTimer = timer.format('DD/MM/YYYY')
  let newTimer = timeTransformer({ time: date, sDateMap })
  return newTimer ? `${ newTimer }` : defaultTimer
}

// 百分比格式化
export const percentFormat = (num) => {
  const newNum = (num * 100) / 100
  return newNum
}
// 模版 折扣文案格式 end


/**
 * 是否在24小时之内
 * @param {*} time 需要判断的时间戳
 * @returns 
 */
const checkWithin24Hours = (time) => {
  if (!time) return false 

  const onDay = 1000 * 24 * 60 * 60
  const now = Date.now()
  return time && now - time < onDay
}



/**
 * 检查 选品 真实列表页券是否在24小时之内显示过，如果显示过则需要剔除
 * @param {String} showCacheKey 用于记录曝光次数的key
 * @param {String[]} cateCouponList 分类券券码列表
 * @param {Number} maxexposelimit 曝光次数
 * @param { Number } thresholdTime 时间阈值，在多少毫秒内存入的不算
 * @returns {String[]} 剔除24小时之内显示过的券的结果
 */
export const filterCouponWithin24Hours = (showCacheKey, cateCouponList = [], maxexposelimit = 0, thresholdTime = 0) => {
  if (!cateCouponList?.length) return []

  let lastShowTime = localStorage.getItem(showCacheKey)
  if (!lastShowTime) {
    return cateCouponList
  }
  const now = Date.now()

  lastShowTime = JSON.parse(lastShowTime)
  if ((typeof lastShowTime !== 'object') || Object.keys(lastShowTime).length === 0) return cateCouponList
  return cateCouponList.filter((couponCode) => {
    // 兼容数据不同的情况
    if (couponCode?.couponCode) {
      couponCode = couponCode?.couponCode
    }
    // 如果不存在
    if (!lastShowTime?.[couponCode] || !Array.isArray(lastShowTime[couponCode])) {
      return true
    }
    const displayCount24Hours = lastShowTime[couponCode].filter((time) => {
      return checkWithin24Hours(time) || (time && thresholdTime && (now - thresholdTime < time))
    })?.length ?? 0
    return displayCount24Hours < maxexposelimit
  })
}

// 选品的cateid是否24小时内在搜索对应的cateid显示过，是则返回true
export const checkCateCouponWithin24Hours = (cateId) => {
  const member_id = UserInfoManager.get({ key: 'memberId', actionType: 'list/coupon' }) ?? ''
  const cateLocalKey = `searchCouponCateIds_${member_id}`


  let cateLocalData = JSON.parse(window.localStorage.getItem(cateLocalKey))

  if (cateLocalData?.[cateId]) {
    return checkWithin24Hours(cateLocalData[cateId])
  }

  return false
}

/**
 * 存储列表页优惠券 显示时间，做显示次数判断
 * @param {String 页面名称} pageName 
 * @param {Array 券列表数据} couponList 
 */
export const setCouponExposeTimes = (pageName, couponList, cateId) => {
  const selectPage = ['page_real_class', 'page_select_class']
  // 是否搜索页
  const isSearchPage = pageName === 'page_search'

  // 是否选品页
  const isSelectPage = selectPage.includes(pageName)

  const now = Date.now()

  const ONEDAY = 1000 * 60 * 60 * 24

  const member_id = UserInfoManager.get({ key: 'memberId', actionType: 'list/coupon' }) ?? ''

  let localKey = ''
  let lastShowData = null
  if (isSearchPage) {
    localKey = 'searchCouponLastShowTime'
    lastShowData = localStorage.getItem(localKey)

    if (lastShowData) {
      lastShowData = JSON.parse(lastShowData)
    } else {
      lastShowData = {}
    }
    couponList.forEach(({ couponCode }) => {
      if (Array.isArray(lastShowData[couponCode])) {
        const newTimes = lastShowData[couponCode].filter(v => now - v < ONEDAY)
        newTimes.push(now)
        lastShowData[couponCode] = newTimes
      } else {
        lastShowData[couponCode] = [now]
      }
    })

    if (cateId) {
      const cateLocalKey = 'searchCouponCateIds_'
      let cateLocalData = JSON.parse(window.localStorage.getItem(`${cateLocalKey}${member_id}`))
      if (cateLocalData) {
        cateLocalData[cateId] = now
      } else {
        cateLocalData = {
          [cateId]: now
        }
      }

      window.localStorage.setItem(cateLocalKey, JSON.stringify(cateLocalData))
    }

  } else if (isSelectPage) {
    localKey = `selectListDisplayedCoupon_${member_id}`
    lastShowData = localStorage.getItem(localKey)

    if (lastShowData) {
      lastShowData = JSON.parse(lastShowData)
    } else {
      lastShowData = {}
    }

    couponList.forEach(({ couponCode }) => {
      couponCode && (lastShowData[couponCode] = [now])
    })
  }

  window.localStorage.setItem(localKey, JSON.stringify(lastShowData))
}

// 绑定搜索券
export const bindSearchCoupon = async (couponResult = []) => {
  const couponCodes = couponResult.map(v => v.coupon)
  if (couponCodes) {
    const res = await schttp({
      method: 'POST',
      url: '/api/coupon/bindCouponsByCoupon/update',
      data: { coupon: couponCodes, scene: 55 }
    })
    const successList = res?.info?.successList ?? []
    const failureList = res?.info?.failureList ?? []
    if (res?.code == '0' && successList.length) {
      return {
        code: '0',
        successList,
        failureList

      }
    } else {
      return {
        code: res.code,
        successList: [],
        failureList
      }
    }

  }

  return {
    code: '0',
    successList: [],
    failureList: []
  }
}

// 绑定券包
export const bindCouponPackage = async (couponResult = {}) => {
  const couponId = couponResult?.info?.couponPackage?.id

  if (couponId) {
    const res = await schttp({
      method: 'POST',
      url: '/api/coupon/bindCouponPackage/update',
      data: { couponId: couponId }
    })
    
    const successList = res?.info?.bindResult?.successList ?? []
    if (res?.code == '0' && successList.length) {
      return {
        code: res.code,
        successList
      }
    } else {
      return {
        code: res.code,
        successList: []
      }
    }
  }
  
  return {
    code: couponResult?.code ?? '0',
    successList: []
  }
}

/**
 * 获取券包/品类券 默认为2者都含有
 *
 * @param {Object} cateCoupon - store中的券信息
 * @param {Object} options - 一些配置信息
 * @param {boolean} options.needCateCoupon - 是否含有品类券
 * @param {boolean} options.needCouponPackage - 是否含有券包券
 * @return {Promise} 返回为Promise包数组
 */
export const getAllCoupon = (cateCoupon, options = {
  needCateCoupon: true,
  needCouponPackage: true
}) => {
  const { needCateCoupon = true, needCouponPackage = true } = options || {}
  const bindCouponHandelList = [null, null]
  // 品类券
  if (needCateCoupon && cateCoupon.cateCoupon) {
    bindCouponHandelList[0] = bindSearchCoupon(cateCoupon.cateCoupon)
  }
  // 券包券
  if ( needCouponPackage && cateCoupon.packageCoupon ) {
    bindCouponHandelList[1] = bindCouponPackage(cateCoupon.packageCoupon)
  }
  return Promise.all(bindCouponHandelList)
}
