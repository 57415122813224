
import { getPreFetchProductList, triggerPreFetchProductList } from '../productList'
import { getPreFetchStoreProductList, triggerPreFetchStoreProductList, isStorePage } from '../store'


export const preFetchGoodsPromise = () => {
  if (typeof window === 'undefined') return Promise.resolve()
  return isStorePage() ? getPreFetchStoreProductList() : getPreFetchProductList()
}

export const triggerPreFetchProductListPromise = () => {
  triggerPreFetchStoreProductList()
  triggerPreFetchProductList();
}