import schttp from 'public/src/services/schttp'
import {
  SEARCH_SOURCE_ENUM,
  ORIGIN_QUERY_FILTER_KEYS
} from 'public/src/pages/common/search_words/const'
import { PRODUCT_LIST_URL_API } from './const.js'

function getUrl({ catInfo }) {
  if (!catInfo) throw new Error('catInfo is required')
  if (catInfo?.type == 'picks') {
    return location.pathname
  }
  return PRODUCT_LIST_URL_API
}

/**
 * @description: 获取商品列表
 * @param {Object} config 请求参数
 * @param {Object} config.catInfo 分类信息
 * @param {Object} config.searchKeywords 搜索关键词
 * @param {Object} config.atomicParams 原子参数
 * @param {Object} config.viewedGoods 已查看商品
 * @param {Object} config.request 请求参数
 *
 */
export async function fetchProductListApi(config, options = {}) {
  const data = await schttp({
    url: getUrl(config),
    params: getFetchParams(config),
    ...options
  })
  return data
}

/**
 * @description 获取请求参数
 * @returns {Object} 请求参数
 **/
export function getFetchParams({
  searchKeywords,
  catInfo,
  atomicParams,
  viewedGoods = [],
  request
}) {
  const { query = {}, type: requestType } = request

  const data = {
    ...getFetchListJsonParams({ catInfo, searchKeywords }),
    ...query,
    requestType,
    // 双语搜索后续请求需带上强制标记
    force_suggest: !!searchKeywords.double_lang_correct,
    // 是否请求付费会员, 在前端存起来, 每次请求的时候带给后端 (前端维护状态比后端容易)
    reqSheinClub: atomicParams.reqSheinClub,
    isPaid: atomicParams.isPaid,
    viewed_goods: viewedGoods.join()
  }
  // 抹掉空参
  Object.keys(data).forEach(key => {
    const needRemainZeroValue =
      ['min_price', 'max_price', 'isPaid', 'sourceStatus'].includes(key) &&
      Number(data[key]) === 0
    if (needRemainZeroValue) return
    !data[key] && delete data[key]
  })

  // data.type === 'search' && 前端新增搜索词类型传参search_source)
  if (data.type === 'search') {
    data.search_source = fixSearchSource(data)
  }
  return data
}

/**
 * @description 获取页面请求参数
 * @returns {Object} 请求参数 { type, routeId, sub_type }
 * */
function getFetchListJsonParams({ catInfo, searchKeywords }) {
  if (!catInfo.type) return {}

  let routeId
  let sub_type
  switch (catInfo.type) {
    case 'entity':
      routeId = catInfo.entity_id
      break
    case 'virtual':
      routeId = catInfo.virtual_id
      break
    case 'selection':
      routeId = catInfo.select_id
      sub_type = catInfo.sub_type
      break
    case 'selling':
      routeId = catInfo.tag_val_id
      break
    case 'search':
      routeId = searchKeywords.is_suggest_res
        ? searchKeywords.suggest_words
        : searchKeywords.origin_words
      break
  }
  return { type: catInfo.type, routeId, sub_type }
}

function fixSearchSource(params) {
  const { pic_nav_id = '', search_source = '' } = params
  let fix = ''
  // 图文导航
  if (pic_nav_id) {
    fix = SEARCH_SOURCE_ENUM.IMG_NAV
  }
  const hasFilter = ORIGIN_QUERY_FILTER_KEYS.some(key => params[key])
  if (hasFilter) {
    fix = SEARCH_SOURCE_ENUM.USER_INPUT
  }
  return fix || search_source
}
